/* eslint-disable react/prop-types */

import React from 'react';
import { Link, graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Seo from '../components/Seo';
import BrandGrid from '../components/BrandGrid/BrandGrid';

export const pageQuery = graphql`
  query SitemapPageQuery {
    allWpPage(sort: { fields: title, order: ASC }) {
      pages: nodes {
        uri
        title
      }
    }
  }
`;

const SitemapPage = ({ data }) => {
  const { pages } = data.allWpPage;

  return (
    <>
      <Seo
        title="Sitemap · Medical Web Experts"
        description="Explore MWE's healthcare IT solutions! Find web, mobile app development & security services for your healthcare organization."
        canonical="/sitemap/"
      />
      <BrandGrid />
      <Container>
        <Row className="justify-content-center">
          <Col lg={8}>
            <h1 className="mb-4">Sitemap</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about-us/">About Us</Link>
              </li>
              <li>
                <Link to="/contact-us/">Contact Us</Link>
              </li>
              <li>
                <Link to="/newsroom/">Newsroom</Link>
              </li>
              <li>
                <Link to="/portfolio/">Portfolio</Link>
              </li>
              <li>
                <Link to="/support/">Support</Link>
              </li>
              {pages.map((page) => {
                return (
                  <li key={page.uri}>
                    <Link to={page.uri}>{page.title}</Link>
                  </li>
                );
              })}
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SitemapPage;
